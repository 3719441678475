@if (isGridView$ | async) {
    <div class="grid-view" pTooltip="Video is private" [tooltipDisabled]="!!lecture().public">
        <a class="card" routerLink="/{{AppRoutes.Videos}}/{{lecture().slug}}/">
            <div class="card-header">
                @if (!lecture().public) {
                    <div class="private-badge">
                        <fa-icon [icon]="faLock" />
                        Private
                    </div>
                }
                <img [src]="part()?.thumbnail || lecture().thumbnail || './assets/download.png'" alt="video-img"
                     [loading]="priorityImageLoading() ? 'eager': 'lazy'" />
                @if (part()?.duration || lecture().duration; as duration) {
                    <app-time-badge [text]="duration | transformTimestamp: 's'" />
                }
            </div>
            <div class="card-body lectures">
                <h4>{{ customLectureTitle }}</h4>
                <div class="py-1 pb-1">
                    <div class="authors">
                        @for (author of lecture().authors; track author.id; let last = $last) {
                            {{ author.name + (last ? '' : ',') }}
                        }
                    </div>

                    <div class="d-flex" style="justify-content: space-between; margin-top: 0.25rem">
                        <p>{{ lecture().published }}</p>
                        <p>&nbsp;·&nbsp;</p>
                        <p>{{ lecture().views }} Views</p>
                    </div>
                </div>
                @if (lecture().type || canDelete()) {
                    <div class="d-flex flex-wrap badge" [class.delete]="canDelete()">
                        <app-badge [text]="lecture().type ?? ''" />
                        @if (canDelete()) {
                            <fa-icon class="delete-icon" [icon]="faTrash" (click)="onDeleteVideo($event)" />
                        }
                    </div>
                }
            </div>
            @if (progress) {
                <div class="progress-bar">
                    <p>{{ minutesLeft ? minutesLeft + " min left" : " " }}</p>
                    <p-progressBar [style]="{ height: '4px'}" color="#FF2E59" [value]="progress" [showValue]="false" />
                </div>
            }
        </a>
    </div>
} @else {
    <div [ngClass]="(isPlaylist() || isBookmark()) ? 'list-view-playlist' : 'video-list-view'">
        @if (isPlaylist() || isBookmark()) {
            <div class="d-flex icon">
                <!--    <app-playlist-menu-dropdown
                style="cursor: pointer"
                (onAction)="onAction($event)"
                *ngIf="isPlaylist"
              ></app-playlist-menu-dropdown> -->
                @if (isBookmark()) {
                    <fa-icon
                            mwlConfirmationPopover
                            popoverTitle="Delete confirmation"
                            popoverMessage="Are you sure you want to remove the lecture from bookmarks?"
                            placement="right"
                            (confirm)="removeFromBookmarksOrPlaylist()"
                            [icon]="faBookmarkSolid"
                    ></fa-icon>
                }
                @if (isPlaylist()) {
                    <fa-icon
                            mwlConfirmationPopover
                            popoverTitle="Delete confirmation"
                            popoverMessage="Are you sure you want to remove the lecture from playlist?"
                            placement="right"
                            (confirm)="removeFromBookmarksOrPlaylist()"
                            [icon]="faTrash"
                    />
                }
            </div>
        }
        <a routerLink="/{{AppRoutes.Videos}}/{{lecture().slug}}/">
            <img [src]="part()?.thumbnail || lecture().thumbnail ||'./assets/download.png'" alt="video-img"
                 [loading]="priorityImageLoading() ? 'eager': 'lazy'" />
        </a>
        <a class="right" routerLink="/{{AppRoutes.Videos}}/{{lecture().slug}}/">
            <div class="info">
                <strong class="title">{{ part()?.title || lecture().title }}</strong>
                @if (lecture().authors?.length) {
                    <div>
                        @if (isBookmark()) {
                            <div class="d-flex flex-wrap">
                                @for (author of lecture().authors; track author; let isLast = $last) {
                                    <p class="pb-1">
                                        {{ author.name }}@if (!isLast) {
                                        <span>, &nbsp;</span>
                                    }
                                    </p>
                                }
                            </div>
                        } @else {
                            @for (author of lecture().authors; track author.id) {
                                @if (author.name) {
                                    <p style="font-weight: 600" class="pb-1">{{ author.name }}</p>
                                }
                            }
                        }
                    </div>
                }
            </div>
            <div class="numbers">
                @if (lecture().published) {
                    <p>{{ lecture().published }}</p>
                }
                <p>{{ lecture().views }} Views</p>
                @if ((isPlaylist() || isBookmark()) && lecture().type; as type) {
                    <app-badge [text]="type" />
                }
            </div>
        </a>
    </div>
}
